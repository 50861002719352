import * as React from 'react'
import * as Icon from 'react-feather'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'
import parse from 'html-react-parser'
import PostArticle from '@base/components/post/PostArticle'
import PostAuthor from '@base/components/post/PostAuthor'
import PostRelatedTaxonomies from '@base/components/post/PostRelatedTaxonomies'
import PostNav, { PostNavFragment } from '@base/components/post/PostNav'

const BlogArticle: React.FC<{
  post?: GatsbyTypes.BlogArticleFragment
  previous?: PostNavFragment
  next?: PostNavFragment
}> = ({ post, next, previous }) => {
  const imageProps =
    post?.featuredImage?.node?.localFile?.childImageSharp?.fluid
  const alt = post?.featuredImage?.node?.altText
  const category = (post?.categories?.nodes ?? [])[0]

  return (
    <PostArticle>
      <div className="article-content">
        {imageProps && (
          <div className="article-image">
            <Image fluid={imageProps} alt={alt} />
          </div>
        )}
        <div className="entry-meta">
          <ul>
            <li>
              <Icon.Clock />
              {post?.date}
            </li>
            {!ARCHIVE && category && (
              <li>
                <Icon.User /> <Link to={category.uri}>{category.name}</Link>
              </li>
            )}
          </ul>
        </div>

        {post?.content && parse(post.content)}
      </div>

      {post?.author?.node && <PostAuthor {...post.author.node} />}

      {!ARCHIVE && post?.tags?.nodes && (
        <PostRelatedTaxonomies tags={post.tags.nodes} />
      )}

      <PostNav previous={previous} next={next} />
    </PostArticle>
  )
}

const query = graphql`
  fragment BlogArticle on WpPost {
    id
    content
    title
    date(formatString: "YYYY年M月D日")
    author {
      node {
        ...PostAuthor
      }
    }

    featuredImage {
      node {
        altText
        localFile {
          publicURL
          childImageSharp {
            fluid(quality: 90, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    categories {
      nodes {
        uri
        name
      }
    }

    tags {
      nodes {
        ...PostRelatedTaxonomies
      }
    }
  }
`

export default BlogArticle
export { query }
