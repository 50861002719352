import * as React from 'react'
import { PageProps, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import PageBanner from '@base/components/pages/PageBanner'
import BlogArticle from '@base/components/post/BlogArticle'
import BlogSidebar from '@base/components/post/BlogSidebar'
import { pageSpecificProps } from '@base/components/app/Layout'

const PageComponent: React.FC<PageProps<GatsbyTypes.BlogTemplateQuery>> = ({
  data: { previous, next, post, file },
}) => {
  const isTopics = (post?.tags?.nodes ?? []).some(
    c => c?.name === 'いちむらTOPICS',
  )

  return (
    <>
      <Helmet
        {...pageSpecificProps({
          title: post?.title,
          description: post?.seo?.opengraphDescription,
          imageUrl: post?.featuredImage?.node?.localFile?.publicURL,
        })}
      />

      <PageBanner pageTitle={post?.title} imageProps={file} topics={isTopics} />
      <div className="blog-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 post-article">
              <BlogArticle {...{ post, previous, next }} />
            </div>
            {!ARCHIVE && (
              <div className="col-lg-4 col-md-12 blog-sidebar">
                <BlogSidebar placeholder="ブログ検索" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const query = graphql`
  query BlogTemplate($id: String!, $previousId: String, $nextId: String) {
    file(relativePath: { eq: "hero/hero-img-7@2x.jpg" }) {
      ...PageBanner
    }

    post: wpPost(id: { eq: $id }) {
      ...BlogArticle
      seo {
        opengraphDescription
      }
    }

    previous: wpPost(id: { eq: $previousId }) {
      ...PostNavTitle
      ...PostNavContent
      ...PostNavFeaturedImage
    }

    next: wpPost(id: { eq: $nextId }) {
      ...PostNavTitle
      ...PostNavContent
      ...PostNavFeaturedImage
    }
  }
`

export default PageComponent
export { query }
