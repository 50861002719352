import * as React from 'react'
import { Link, graphql } from 'gatsby'
import * as Icon from 'react-feather'

const PostAuthor: React.FC<GatsbyTypes.PostAuthorFragment> = ({
  name,
  avatar,
  slug,
}) => {
  return (
    <div className="author">
      <div className="author__box">
        <div className="author__img">
          {avatar?.url && <img src={avatar.url} />}
        </div>
        <div className="author__info">
          <h5 className="author__info__heading">この記事の筆者</h5>
          <h6 className="author__info__name">{name}</h6>
          {!ARCHIVE && (
            <Link to={`/blog/author/${slug}`} className="read-more-btn">
              筆者の他の記事を読む <Icon.ArrowRight />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

const query = graphql`
  fragment PostAuthor on WpUser {
    name
    slug
    avatar {
      url
    }
  }
`

export default PostAuthor
export { query }
