import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'

type PostNavFragment = GatsbyTypes.PostNavTitleFragment &
  GatsbyTypes.PostNavContentFragment &
  Partial<GatsbyTypes.PostNavFeaturedImageFragment>

const PostNav: React.FC<{
  previous?: PostNavFragment
  next?: PostNavFragment
}> = ({ previous, next }) => {
  return (
    <div className="post-nav">
      {previous && <Previous {...previous} />}
      {next && <Next {...next} />}
    </div>
  )
}

const Previous: React.FC<PostNavFragment> = ({
  uri,
  title,
  date,
  featuredImage,
}) => {
  const imageProps = featuredImage?.node?.localFile?.childImageSharp?.fluid

  return (
    <div className="post-nav__wrapper post-nav__wrapper--prev">
      <Link to={uri}>
        <div className="post-nav__img post-nav__img--prev">
          {imageProps && <Image fluid={imageProps} />}
          <span className="post-nav-title">Prev</span>
        </div>
        <div className="post-nav__info post-nav__info--prev">
          <span className="post-nav__title">{title}</span>
          <span className="post-nav__meta">
            <span className="date-post">{date}</span>
          </span>
        </div>
      </Link>
    </div>
  )
}

const Next: React.FC<PostNavFragment> = ({
  uri,
  title,
  date,
  featuredImage,
}) => {
  const imageProps = featuredImage?.node?.localFile?.childImageSharp?.fluid
  return (
    <div className="post-nav__wrapper post-nav__wrapper--next">
      <Link to={uri}>
        <div className="post-nav__info post-nav__info--next">
          <span className="post-nav__title">{title}</span>
          <span className="post-nav__meta">
            <span className="date-post">{date}</span>
          </span>
        </div>
        <div className="post-nav__img post-nav__img--next">
          {imageProps && <Image fluid={imageProps} />}
          <span className="post-nav-title">Next</span>
        </div>
      </Link>
    </div>
  )
}

const query = graphql`
  fragment PostNavTitle on WpNodeWithTitle {
    title
  }
  fragment PostNavContent on WpContentNode {
    uri
    date(formatString: "YYYY年M月D日")
  }
  fragment PostNavFeaturedImage on WpNodeWithFeaturedImage {
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default PostNav
export { query, PostNavFragment }
